import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './CSS/Style.css';
import Wrap from './Components/Wrap';

function NotFound() {
  return <center><h2>404 Not Found</h2></center>;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Wrap />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
